















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import CostCenterMenu from './Menu.vue'
import CostCenterConfigService from '@/services/economy/CostCenterConfigService'
import CostCenterConfig from '@/models/economy/CostCenterConfig'

@Component({
  components: {
    CostCenterMenu,
  },
})
export default class Edit extends Vue {
  private isSaving = false
  private data: CostCenterConfig = null
  private original: CostCenterConfig = null
  private rules = {}
  private error = ''
  private costCenters = []

  public created(): void {
    this.data = new CostCenterConfig()
    this.original = new CostCenterConfig()
    this.rules = {}
    this.load()
  }

  private load() {
    CostCenterConfigService.getAllCostCenters()
      .then((costCenters) => {
        this.costCenters = costCenters

        CostCenterConfigService.loadOne()
          .then((costCenterConfig) => {
            this.data = costCenterConfig
            this.original = costCenterConfig.clone()
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err)
          })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }

    CostCenterConfigService.update(this.data)
      .then((_response) => {
        vxm.alert.success(this.$t('c:cost-center-config:Config updated'))
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not save cost center config')
      })
  }

  private clickCancel() {
    this.$router.back()
  }
}
